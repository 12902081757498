import React, { useState } from 'react';
import StructuredText from 'src/components/base/structuredText';
import FormControl from 'src/components/base/formControl';
import { STATES, submitForm } from 'src/util';
import * as classes from './blockReferForm.module.scss';

const BlockReferForm = ({
  patientHeader,
  preformContent,
  patientPostformContent,
  referrerHeader,
  otherHeader,
  otherPreformContent,
  buttonText,
  onComplete,
}) => {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [birthdate, setBirthdate] = useState();
  const [phone, setPhone] = useState();
  const [state, setState] = useState();

  const [referrerFirstName, setReferrerFirstName] = useState();
  const [referrerLastName, setReferrerLastName] = useState();
  const [referrerOrganization, setReferrerOrganization] = useState();

  const [other, setOther] = useState();

  return (
    <div>
      <form
        id="refer"
        action={process.env.GATSBY_REFER_HANDLER}
        onSubmit={(evt) => submitForm(evt, [], onComplete)}
      >
        <StructuredText data={preformContent} />

        <input type="hidden" name="id" value="onboarding" />
        <input type="hidden" name="requestType" value="Referral" />

        <h2>{patientHeader}</h2>
        <FormControl
          name="firstName"
          label="First name"
          value={firstName}
          setValue={setFirstName}
          required
        />
        <FormControl
          name="lastName"
          label="Last name"
          value={lastName}
          setValue={setLastName}
          required
        />
        <FormControl
          name="phoneNumber"
          required
          type="tel"
          label="Phone number"
          value={phone}
          setValue={setPhone}
        />
        <FormControl
          name="birthdate"
          type="date"
          label="Birthdate"
          value={birthdate}
          setValue={setBirthdate}
        />
        <FormControl
          name="state"
          label="Primary state of residence"
          options={STATES}
          valueFn={(s) => s.name}
          labelFn={(s) => s.name}
          value={state}
          setValue={setState}
        />
        <StructuredText data={patientPostformContent} />

        <h2>{referrerHeader}</h2>
        <FormControl
          name="referrerFirstName"
          label="First name"
          value={referrerFirstName}
          setValue={setReferrerFirstName}
          required
        />
        <FormControl
          name="referrerLastName"
          label="Last name"
          value={referrerLastName}
          setValue={setReferrerLastName}
          required
        />
        <FormControl
          name="referrerPracticeName"
          label="Practice/organization name"
          value={referrerOrganization}
          setValue={setReferrerOrganization}
          required
        />

        <h2>{otherHeader}</h2>
        <StructuredText data={otherPreformContent} className={classes.content} />
        <FormControl
          name="additional"
          label="Anything else you'd like us to know?"
          value={other}
          setValue={setOther}
          multi
        />

        <div className={classes.cta}>
          <button type="submit" className="button">
            {buttonText}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlockReferForm;
